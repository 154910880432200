exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/articleList.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-comic-js": () => import("./../../../src/templates/comic.js" /* webpackChunkName: "component---src-templates-comic-js" */),
  "component---src-templates-draft-js": () => import("./../../../src/templates/draft.js" /* webpackChunkName: "component---src-templates-draft-js" */),
  "component---src-templates-illustration-js": () => import("./../../../src/templates/illustration.js" /* webpackChunkName: "component---src-templates-illustration-js" */),
  "component---src-templates-index-comic-js": () => import("./../../../src/templates/indexComic.js" /* webpackChunkName: "component---src-templates-index-comic-js" */),
  "component---src-templates-index-interview-js": () => import("./../../../src/templates/indexInterview.js" /* webpackChunkName: "component---src-templates-index-interview-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-index-novel-js": () => import("./../../../src/templates/indexNovel.js" /* webpackChunkName: "component---src-templates-index-novel-js" */),
  "component---src-templates-interview-js": () => import("./../../../src/templates/interview.js" /* webpackChunkName: "component---src-templates-interview-js" */),
  "component---src-templates-novel-js": () => import("./../../../src/templates/novel.js" /* webpackChunkName: "component---src-templates-novel-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

