import React from 'react'
import { ApolloProvider } from '@apollo/client'
import graphql_client from 'src/graphqlClient'
import Layout from 'layouts/BasicLayout'

export default ({ element }) => {
  return (
    <ApolloProvider client={graphql_client}>
      <Layout>{element}</Layout>
    </ApolloProvider>
  )
}
